import { useMediaQuery } from '@material-ui/core'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import regularDesktop from './images/regular-desktop.svg'
import regularMobile from './images/regular-mobile.svg'
import stickyDesktop from './images/sticky-desktop.svg'
import regularUpdatedMobile from './images/regular-updated-mobile.svg'
import regularUpdatedDesktop from './images/regular-updated-desktop.svg'

export type CreditUnionLogoProps = {
  notext?: boolean
}

export const StyledLogoContainer = styled.figure<{ $width: number; $height: number }>`
  margin: 0;
  display: flex;
  position: relative;
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;

  ${props => props.theme.breakpoints.down('xs')} {
    span {
        width: 100% !important;
    }    
  }
`

type LogoVariant = 'home-desktop' | 'regular-desktop' | 'regular-tablet' | 'regular-mobile' | 'sticky-desktop' | 'sticky-tablet' | 'sticky-mobile' | 'regular-updated-mobile' | 'regular-updated-desktop'

const logoMap: Record<LogoVariant, string> = {
  'home-desktop' : regularUpdatedDesktop,
  'regular-desktop': regularUpdatedDesktop,
  'regular-tablet': regularUpdatedDesktop,
  'regular-mobile': regularUpdatedMobile,
  'sticky-desktop': stickyDesktop,
  'sticky-tablet': stickyDesktop,
  'sticky-mobile': stickyDesktop,
  'regular-updated-mobile': regularUpdatedMobile,
  'regular-updated-desktop': regularUpdatedDesktop,
}
const sizeMap: Record<LogoVariant, [number, number]> = {
  'home-desktop' : [147, 70],
  'regular-desktop': [117, 56],
  'regular-tablet': [99, 48],
  'regular-mobile': [99, 48],
  'sticky-desktop': [41, 54],
  'sticky-tablet': [30, 40],
  'sticky-mobile': [30, 40],
  'regular-updated-desktop': [117, 56],
  'regular-updated-mobile': [99, 48],
}

export const CreditUnionLogo: React.FC<PropsWithChildren<CreditUnionLogoProps>> = ({ notext, ...props }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'))
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))
  const router = useRouter()

  const variant: LogoVariant = useMemo(() => {
    if (isTablet && !isDesktop) {
      return notext ? 'sticky-tablet' : 'regular-tablet'
    } else if (isDesktop && router.route === '/') {
      return notext ? 'sticky-desktop' : 'home-desktop'
    } else if (isDesktop) {
      return notext ? 'sticky-desktop' : 'regular-desktop'
    } else {
      return notext ? 'sticky-mobile' : 'regular-mobile'
    }
  }, [notext, isDesktop, isTablet])

  const src = useMemo(() => logoMap[variant], [variant])
  const [width, height] = useMemo(() => sizeMap[variant], [variant])

  return (
    <StyledLogoContainer $width={width} $height={height}>
      <Image priority src={src} width={width} height={height} { ...props } alt={'logo'} />
    </StyledLogoContainer>
  )
}

export const StyledCreditUnionLogo = styled(CreditUnionLogo)`
  transition: all 0.2s ease;
`
